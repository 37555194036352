import React from "react"
import { Container, Col, Row, Table, Image, Figure } from "react-bootstrap"
import { graphql } from "gatsby"
//import { Link } from "gatsby"
import Layout from "../../components/layoutHome"
import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';
import {Typography,Grid,Paper} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
Title:{
        width:"100%",
        display:"flex",
        justifyContent:"Center",
        //margin:"30px",
        height:"30px",
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        fontSize:"28px",
        alignItems:"center"
      },
      SubTitle:{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginBottom:"10px",
        textAlign:"justify",
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        
      },
      imgContainer:{
          padding:"5px",
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",

      },
      imgText:{
          textAlign:"center",

      },
      img:{
          border:"black",
          borderStyle:"groove"
      },
      GridContainer:{
          margin:"10px"
      },
      EventContainer:{
        margin:"10px",
        width:"100%"
      },
      TextEvent:{
        width:"100%",
        padding:"10px",
        textAlign:"justify"
      },
      Seccion:{
          marginTop:"10px",
          marginBottom:"10px",
          paddingLeft:"10px",
          paddingRight:"10px"
      },
      espacio:{
          padding:"5px"
      },
      full:{
          width:"100%"
      },
      Principal:{
        maxHeight:"550px",
        maxWidth:"100%",
        padding:"10px",
      }

  }));

const Eventos = ({ data }) => {
  const Model = data.allCeformaPagesShow7.edges[0].node.data.page.content
  const eventos = data.allCeformaPagesShow7.edges[0].node.data.page.content.Events
  const classes = useStyles(); 
  console.log(Model)
  return (
    <Layout>
      <SEO title="Eventos" />
      <Container fuid>
        <Typography className={classes.Title}  >
            Eventos
        </Typography>

        <Grid Container
        className={classes.full}
        >
           
        {
        eventos.length > 0 ? (
          <Grid container 
          className={classes.full}
          >
        
          {eventos.map((Article , index) => {
          return (
            <Paper className={classes.EventContainer}> 
                  <React.Fragment>
                      <Typography variant="h5" className={classes.SubTitle} >
                      {Article.title}
                      </Typography>
                      <Image src={Article.imgPrincipal} rounded 
                      className={classes.Principal}
                      />
                     
                     <Typography className={classes.TextEvent}>
                     {Article.description}
                     </Typography>
                     
                     {
                    Article.imgs.length > 0 ? (
                    <Grid container 
                    className={classes.Seccion}
                    >
                    {Article.imgs.map(( img , index) => {
                    return (
                  <Grid iteam xs={12} sm={6} md={6} lg={4} xl={4} 
                  className={classes.espacio}
                  
                  >
                    <Paper className={classes.imgContainer}>
                     <Figure>
                        <Figure.Image
                            width={"100%"}
                            height={231}
                            alt={img.ImgDescripcion}
                            src={img.ImgUrl}
                            className={classes.img}
                        />
                        <Figure.Caption className={classes.imgText}>
                        {img.ImgDescripcion}
                        </Figure.Caption>
                    </Figure>
                    </Paper>
                  </Grid>      
                  );
                })}
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}
                     
                     {
        Article.docs.length > 0 ? (
          <Grid container >
          {Article.docs.map(( doc , index) => {
          return (
            <Grid iteam xs={12} sm={4} md={4} lg={3} xl={3}
            className={classes.espacio} 
            >
            <Paper className={classes.imgContainer}>
            <a href={doc.DocUrl} className={classes.max}>    
             <Figure>
                <Figure.Image
                    width={"100%"}
                    height={200}
                    alt={doc.DocDescripcion}
                    src="https://www.colegioays.com/wp-content/uploads/2016/06/documentos.png"
                    className={classes.img}
                />
                <Figure.Caption className={classes.imgText}>
                {doc.DocDescripcion}
                </Figure.Caption>
            </Figure>
            </a>
            </Paper>
          </Grid>      
                  );
                })}
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}




                  </React.Fragment>  
                  </Paper>       
                  );
                })}
             
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}
            
        </Grid>
      </Container>
    </Layout>
  )
}

export default  Eventos 

export const query = graphql`
  query events {
    allCeformaPagesShow7 {
        edges {
          node {
            data {
              page {
                content {
                  Events {
                    docs {
                      DocDescripcion
                      DocUrl
                    }
                    imgs {
                      ImgDescripcion
                      ImgUrl
                    }
                    description
                    title
                    imgPrincipal
                  }
                  title
                  welcomeText
                }
              }
            }
          }
        }
      }
  }
`
